<template>
  <div><router-view></router-view></div>
</template>

<style lang="scss">
@function px2rem($px) {
  @return ($px / 13) + rem;
}

@function px2vw($px) {
  @return ($px * 100 / 1920) + vw;
}

@function px2vh($px) {
  @return ($px * 100 / 1080) + vh;
}

html {
  background-color: rgba(0, 0, 0, 1);
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }
}

#app {
  font-family: "Argentum Sans";
  color: rgba(255, 255, 255, 1);
  overflow-x: hidden;
}
</style>
